import { index, pgTable, primaryKey, text, uniqueIndex } from 'drizzle-orm/pg-core';
import { marketOrderColumns } from '../../../db/drizzle-shared/market_orders.columns';
import { partitionColumns } from '../../../db/drizzle-shared/partition_columns';
export const marketOrders = pgTable('market_orders', {
    ...partitionColumns,
    ...marketOrderColumns,
    organizationId: text('organization_id').notNull(),
}, (table) => [
    primaryKey({
        columns: [table.marketId, table.merchantId, table.orderId],
        name: 'market_orders_pk',
    }),
    uniqueIndex('market_orders_unique_key').on(table.marketId, table.merchantId, table.orderId),
    /* query for secondaryId */
    index().on(table.marketId, table.merchantId, table.secondaryOrderId),
    /* query for createdAt */
    index().on(table.marketId, table.merchantId, table.createdAt),
]);
