/* eslint-disable perfectionist/sort-objects */
import { sql } from 'drizzle-orm';
import { jsonb, text } from 'drizzle-orm/pg-core';
import { createOptionalTimestamp } from './columns';
/**
 * we don't have organizationId here because we don't need that on the client
 */
export const marketOrderColumns = {
    // HINT: for orders from markets, this should be the order id given BY the market. Or it can be a random id in case it is a manually created order
    orderId: text('order_id')
        .notNull()
        .default(sql `gen_random_uuid ()`),
    /* an optional secondary id */
    secondaryOrderId: text('secondary_order_id'),
    /**
     * connects an order to an installed market connection.
     * if null, it means it's created manually. Otherwise,
     * we need a marketconnection for manually created orders for EACH org
     *
     * references composite key from marketConnections in cloud db
     */
    marketId: text('market_id').notNull(),
    merchantId: text('merchant_id').notNull(),
    /* timestamp when the order was created */
    createdAt: createOptionalTimestamp('created_at'),
    /**
     * we need to keep track when an order was last updated
     * to be able to continue synchronization.
     * Must always be provided either by the market or manually
     */
    updatedAt: createOptionalTimestamp('updated_at'),
    /**
     * if that is being set, we know that a mutation is going on
     * and can show a pending state to all users
     * HINT: maybe just use a timestamp:mutationId (so we know WHEN something happened and can associate the mutationId with a log entry)
     */
    fulfilledMutationId: text('fulfilled_mutation_id').default(sql `gen_random_uuid ()`),
    /* this determines when we fulfilled an order with the marketplace api (or manually) */
    fulfilledAt: createOptionalTimestamp('fulfilled_at'),
    // use this to determine if the order is archived
    archivedAt: createOptionalTimestamp('archived_at'),
    /**
     * if that is being set, we know that a mutation is going on
     * and can show a pending state to all users
     */
    returnHandledMutationId: text('return_handled_mutation_id').default(sql `gen_random_uuid ()`),
    /* LATER: this determines when we handled a return */
    returnHandledAt: createOptionalTimestamp('return_handled_at'),
    /**
     * if this is set, it will override the address in the order
     * TODO: use common schema or even a reference
     */
    consigneeAddress: jsonb('consignee_address'),
    /**
     * sorted list of eans used to group by bundles and track shipped positionItems
     */
    eanList: text('ean_list').array().default([]),
    /**
     * sorted list of skus used to group by bundles and track shipped positionItems
     * HINT: prefer SKUs over EANs as the latter might be undefined (at least for Otto)
     */
    skuList: text('sku_list').array().default([]),
    /* this is used to bundle orders (and for analytics). Should be the skus or something similar */
    // lineItemIds: text('line_item_ids').array().default([]),
    // TODO: add basic status (at least `PROCESSABLE` and `FULFILLED`) to be able to filter without going through `raw` in local client db
};
