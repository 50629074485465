/* eslint-disable perfectionist/sort-objects */
import { sql } from 'drizzle-orm';
import { jsonb, text } from 'drizzle-orm/pg-core';
import { carrierLabelTypeEnum } from '../drizzle-pg-cloud-schema/enums';
import { createOptionalTimestamp, createRequiredTimestampWithDefaultNow } from './columns';
/**
 * we don't have organizationId here because we don't need that on the client
 */
export const carrierLabelColumns = {
    /**
     * we use this as "mutation id"
     */
    id: text('id')
        .$type()
        .primaryKey()
        .default(sql `gen_random_uuid ()`),
    createdAt: createRequiredTimestampWithDefaultNow('created_at'),
    /**
     * in case a label is cancelled by the user, we save the timestamp here
     */
    cancelledAt: createOptionalTimestamp('cancelled_at'),
    /**
     * if this is set, it will override the address in the order
     */
    consigneeAddress: jsonb('consignee_address'),
    /**
     * in case we encounter an error, we can save it here and
     * see it as unsuccessful
     */
    error: text('error'),
    labelUrl: text('label_url'),
    // marketOrderLineItemIds: text('market_order_line_item_ids').array().default([]),
    /* market connection parts */
    marketId: text('market_id').notNull(),
    merchantId: text('merchant_id').notNull(),
    /* market order parts */
    orderId: text('order_id').notNull(),
    orderSecondaryId: text('order_secondary_id'),
    trackingId: text('tracking_id'),
    type: carrierLabelTypeEnum('type'),
    // use this to store additional info about services etc if necessary
    meta: jsonb('meta'),
};
