import { sql } from 'drizzle-orm';
import { check, index, pgTable, primaryKey, text } from 'drizzle-orm/pg-core';
import { productsColumns } from '../../../db/drizzle-shared/products.columns';
export const products = pgTable('products', {
    ...productsColumns,
    organizationId: text('organization_id').notNull(),
}, (table) => [
    primaryKey({
        columns: [table.id],
        name: 'products_pk',
    }),
    index().on(table.ean),
    index().on(table.sku),
    check('stock_quantity_available_check', sql `
				${table.stockQuantityAvailable} > 0
				AND ${table.stockQuantityAvailable} >= ${table.stockQuantityReserved}
			`),
    check('stock_quantity_reserved_check', sql `${table.stockQuantityReserved} >= 0`),
    check('stock_quantity_low_check', sql `${table.stockQuantityLow} >= 0`),
]);
