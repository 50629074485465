import { foreignKey, index, pgTable, text } from 'drizzle-orm/pg-core';
import { carrierLabelColumns } from '../../../db/drizzle-shared/carrier_labels.columns';
import { partitionColumns } from '../../../db/drizzle-shared/partition_columns';
import { marketOrders } from './market_orders';
/**
 * TODO:
 * - think about all columns again (according to market_orders)
 * - create additional indexes for type, order id, trackingId etc.
 */
export const carrierLabels = pgTable('carrier_labels', {
    ...partitionColumns,
    ...carrierLabelColumns,
    /** ----------------------------------------------------------------------------------------------
     * composite key parts to link to an order
     * _______________________________________________________________________________________________ */
    /* belongs to a carrier_connection */
    // TODO we also need this for the local table but without the reference !
    carrierId: text('carrier_id'),
}, (table) => [
    // Add foreign key reference to market_orders using all primary key columns
    foreignKey({
        columns: [table.marketId, table.merchantId, table.orderId],
        foreignColumns: [marketOrders.marketId, marketOrders.merchantId, marketOrders.orderId],
        name: 'carrier_labels_market_order_fk',
    }).onUpdate('cascade'),
    // add index for trackingId only
    index().on(table.trackingId),
    /** ----------------------------------------------------------------------------------------------
     * use for synchronization
     * _______________________________________________________________________________________________ */
    index().on(table._yearMonth),
    index().on(table._yearWeek),
]);
