import { index, pgTable, serial, text, timestamp } from 'drizzle-orm/pg-core';
export const printjobs = pgTable('printjobs', {
    _id: serial('_id').primaryKey(),
    createdAt: timestamp('createdAt', { mode: 'date', precision: 3 }).notNull().defaultNow(),
    eid: text('eid'),
    eid2: text('eid2'),
    hostname: text('hostname').notNull(),
    /*
     * globalId is our global frachter id (like printjob:order:xyz)
     */
    id: text('id').notNull(),
    printerName: text('printerName').notNull(),
    /**
     * we use ref to store a list of skus that are part of this printjob
     */
    ref: text('ref').array(),
    /**
     * we use ref2 to store a list of line item ids that are part of this printjob
     */
    ref2: text('ref2').array(),
    status: text('status').$type().notNull(),
    type: text('type').$type().notNull(),
    updatedAt: timestamp('updatedAt', { mode: 'date', precision: 3 }).$onUpdate(() => new Date()),
    /**
     * url to the document printed
     */
    url: text('url').notNull(),
}, (table) => [
    index('created_at_idx').on(table.createdAt),
    index('eid2_idx').on(table.eid2),
    index('eid_idx').on(table.eid),
    index('id_idx').on(table.id),
    index('status_idx').on(table.status),
    index('type_idx').on(table.type),
]);
