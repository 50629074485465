/* eslint-disable perfectionist/sort-objects */
import { sql } from 'drizzle-orm';
import { integer, numeric, text } from 'drizzle-orm/pg-core';
import { createOptionalTimestampWithDefaultNow, createRequiredTimestampWithDefaultNow } from './columns';
export const productsColumns = {
    /* timestamp when the order was created */
    createdAt: createOptionalTimestampWithDefaultNow('created_at'),
    updatedAt: createRequiredTimestampWithDefaultNow('updated_at').$onUpdate(() => new Date()),
    id: text('id')
        .$type()
        .default(sql `gen_random_uuid ()`),
    ean: text('ean'),
    sku: text('sku'),
    title: text('title'),
    description: text('description'),
    imageUrl: text('image_url'),
    price: numeric('price', { precision: 10, scale: 2 }),
    currency: text('currency'),
    weight: numeric('weight', { precision: 10, scale: 2 }),
    weightUnit: text('weight_unit'),
    stockQuantityAvailable: integer('stock_quantity_available').default(0),
    stockQuantityReserved: integer('stock_quantity_reserved').default(0),
    stockQuantityLow: integer('stock_quantity_low').default(0),
};
