import { z } from 'zod';
export const specApiSchema = z.object({
    baseUrl: z.object({
        production: z.string().url(),
        sandbox: z.string().url().optional(),
    }),
    displayName: z.string(),
    documentation: z.array(z.object({
        category: z.enum(['api', 'additional']),
        url: z.string().url(),
    })),
    id: z.string(),
    provider: z.string().optional(),
    type: z.enum(['carrier', 'market']),
    version: z.string(),
});
export const marketsApiSchema = z.object({
//
});
export const carriersApiSchema = z.object({
//
});
