import { pgEnum } from 'drizzle-orm/pg-core';
import { carrierProviderEnumSchema } from '../../schema/carriers';
import { carrierLabelTypeEnumSchema } from '../../schema/warehouse';
import { genderSchema } from '../../schema/address';
import { countryCodeIso31661Alpha2Schema } from '../../schema/country-codes-alpha2';
import { apiEnvironmentEnumSchema } from '../../schema/system';
// export const marketProviderEnum = pgEnum('market_provider', marketProviderEnumSchema.options);
export const environmentEnum = pgEnum('api_environment', apiEnvironmentEnumSchema.options);
export const countryCodeEnum = pgEnum('CountryCode', countryCodeIso31661Alpha2Schema.options);
export const genderEnum = pgEnum('gender_enum', genderSchema.options);
export const carrierProviderEnum = pgEnum('carrier_provider', carrierProviderEnumSchema.options);
export const carrierLabelTypeEnum = pgEnum('carrier_label_type', carrierLabelTypeEnumSchema.options);
