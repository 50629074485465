import { relations } from 'drizzle-orm';
import { index, jsonb, pgTable, primaryKey, text } from 'drizzle-orm/pg-core';
import { marketOrderColumns } from '../../drizzle-shared/market_orders.columns';
import { marketOrderAddresses } from './market_order_addresses';
export const marketOrdersLocal = pgTable('market_orders_local', {
    ...marketOrderColumns,
    /**
     * this column is used to store the raw data from the market
     */
    raw: jsonb('raw').notNull(),
    /**
     * this column is used to quickly filter for orders
     * and to make sure we don't have to go through the `raw` column
     *
     * use text to avoid problems when adding / removing values from the enum
     * and to be compatible with sqlite in case
     *
     * @see {@link marketOrderStatusEnumV2Schema} for mapping hints
     */
    status: text('status').$type().notNull(),
}, (table) => [
    primaryKey({ columns: [table.marketId, table.merchantId, table.orderId] }),
    index().on(table.orderId),
    /* query for secondaryId */
    index().on(table.secondaryOrderId),
    index().on(table.marketId, table.secondaryOrderId),
    index().on(table.marketId, table.merchantId, table.secondaryOrderId),
    /* query for createdAt */
    index().on(table.createdAt),
    index().on(table.marketId, table.createdAt),
    index().on(table.marketId, table.merchantId, table.createdAt),
    /* query for status */
    index().on(table.status),
    index().on(table.marketId, table.status),
    index().on(table.marketId, table.merchantId, table.status),
    /* query for eanList */
    index().on(table.eanList),
    index().on(table.marketId, table.eanList),
    index().on(table.marketId, table.merchantId, table.eanList),
    /* query for skuList */
    index().on(table.skuList),
    index().on(table.marketId, table.skuList),
    index().on(table.marketId, table.merchantId, table.skuList),
]);
export const marketOrdersRelations = relations(marketOrdersLocal, ({ many }) => ({
    addresses: many(marketOrderAddresses),
}));
