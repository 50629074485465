import { relations } from 'drizzle-orm';
import { foreignKey, pgTable, text, primaryKey, index } from 'drizzle-orm/pg-core';
import { makeAddressBaseColumns } from '../../../db/drizzle-shared/address_base.columns';
import { marketOrdersLocal } from './market_orders_local';
export const marketOrderAddresses = pgTable('market_order_addresses', {
    ...makeAddressBaseColumns(),
    addition1: text('addition1'),
    addition2: text('addition2'),
    /**
     * usually the company name (or first name and last name)
     */
    name1: text('name1'),
    /**
     * usually first name and last name if company name is set, otherwise empty.
     */
    name2: text('name2'),
    /**
     * name addition. Usually not needed.
     */
    name3: text('name3'),
    /**
     * foreign key parts of table orders
     */
    orderId: text('order_id').notNull(),
    orderMarketId: text('order_market_id').notNull(),
    orderMerchantId: text('order_merchant_id').notNull(),
    /**
     * to be used to distinguish between auto-created addresses through
     * normalization and user edited addresses, which should take precedence
     */
    type: text('type').$type().notNull(),
}, (table) => [
    /**
     * set a primary key over the order composite keys and the type
     * to ensure that we ever only have ONE market type address per order and at most
     * one user edited address per order
     */
    primaryKey({ columns: [table.orderId, table.orderMarketId, table.orderMerchantId, table.type], name: 'pk' }),
    foreignKey({
        columns: [table.orderId, table.orderMarketId, table.orderMerchantId],
        foreignColumns: [marketOrdersLocal.orderId, marketOrdersLocal.marketId, marketOrdersLocal.merchantId],
    })
        .onDelete('cascade')
        .onUpdate('cascade'),
    index().on(table.countryCode),
]);
export const marketOrderAddressesRelations = relations(marketOrderAddresses, ({ one }) => ({
    order: one(marketOrdersLocal, {
        fields: [marketOrderAddresses.orderId, marketOrderAddresses.orderMarketId, marketOrderAddresses.orderMerchantId],
        references: [marketOrdersLocal.orderId, marketOrdersLocal.marketId, marketOrdersLocal.merchantId],
    }),
}));
