import { text } from 'drizzle-orm/pg-core';
import { countryCodeEnum } from '../drizzle-pg-cloud-schema/enums';
import { createRequiredTimestampWithDefaultNow } from './columns';
export const makeAddressBaseColumns = () => ({
    city: text('city'),
    /**
     * if country code is set, we prefer this over the country
     */
    countryCode: countryCodeEnum('country_code'),
    createdAt: createRequiredTimestampWithDefaultNow('created_at'),
    email: text('email'),
    house: text('house'),
    phone: text('phone'),
    state: text('state'),
    street: text('street'),
    updatedAt: createRequiredTimestampWithDefaultNow('updated_at').$onUpdate(() => new Date()),
    zip: text('zip'),
});
